import { render, staticRenderFns } from "./quotation-channel.vue?vue&type=template&id=fbc4c30e&scoped=true"
import script from "./quotation-channel.vue?vue&type=script&lang=js"
export * from "./quotation-channel.vue?vue&type=script&lang=js"
import style0 from "./quotation-channel.vue?vue&type=style&index=0&id=fbc4c30e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cm2j7qxmayqayl6phxhnflalsy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc4c30e",
  null
  
)

export default component.exports